@import './variables';

html {
  color: $black;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-weight: unset;
  margin: 0;
}

b {
  font-weight: 500;
}

h1 {
  font-family: 'Caveat', cursive;
  font-size: 96px;
  line-height: 96px;
  font-weight: 500;
  max-width: 900px;
  margin: 0 auto;

  @media #{$tablet} {
    font-size: calc(60px + (96 - 60) * #{$resize-ratio});
    line-height: calc(60px + (96 - 60) * #{$resize-ratio});
    max-width: 80vw;
  }

  @media #{$mobile} {
    font-size: 60px;
    line-height: 60px;
    max-width: 90vw;
    margin: 0 auto;
  }
}

h2 {
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  max-width: 900px;
  margin: 0 auto;

  @media #{$tablet} {
    font-size: calc(24px + (36 - 24) * #{$resize-ratio});
    line-height: calc(24px + (36 - 24) * #{$resize-ratio});
    max-width: 80vw;
  }

  @media #{$mobile} {
    font-size: 24px;
    line-height: 24px;
    max-width: 90vw;
    margin: 0 auto;
  }
}

h3 {
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  max-width: 900px;
  margin: 0 auto;
  padding-top: 36px;
  padding-bottom: 36px;

  @media #{$tablet} {
    font-size: calc(18px + (36 - 18) * #{$resize-ratio});
    line-height: calc(18px + (36 - 18) * #{$resize-ratio});
    max-width: 80vw;
  }

  @media #{$mobile} {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 18px;
    line-height: 18px;
    max-width: 85vw;
  }
}

a {
  font-weight: 400;
  color: $blue;
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px solid rgba($black, 0.2);
  max-width: 900px;
  margin: 0 auto;

  @media #{$tablet} {
    max-width: 80vw;
  }

  @media #{$mobile} {
    max-width: 85vw;
  }
}

button {
  font-size: 24px;
  border: 1px solid $black;
  border-radius: 6px;
  background: transparent;
  padding: 16px 32px;
  cursor: pointer;
  color: $black;
  min-width: 250px;

  &.disabled {
    background-color: rgba($black, 0.3);
    border-color: rgba($black, 0.3);
    color: rgba($black, 0.3);
    pointer-events: none;

    &.red {
      background-color: rgba($red, 0.3);
      border-color: rgba($red, 0.3);
      color: rgba($white, 0.8);
    }
  }

  &.red {
    border: 1px solid $red;
    color: $red;

    &:hover {
      background-color: $red;
    }
  }

  @media #{$mobile} {
    font-size: 16px;
    padding: 12px 20px;
    min-width: 180px;
  }

  &:focus,
  &:hover {
    outline: 0;
  }

  &:hover {
    background-color: $black;
    color: $white;
  }
}

p {
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  max-width: 900px;
  margin: 0 auto;

  @media #{$tablet} {
    font-size: calc(16px + (32 - 16) * #{$resize-ratio});
    line-height: calc(24px + (48 - 24) * #{$resize-ratio});
    max-width: 80vw;
  }

  @media #{$mobile} {
    font-size: 16px;
    line-height: 24px;
    max-width: 85vw;
    margin: 0 auto;
  }
}

span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  max-width: 275px;
  margin: 0 auto;

  &.error {
    color: $red;
  }
}

div {
  box-sizing: border-box;
}
