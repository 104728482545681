@import '../styles/variables';

.app {
  position: relative;
  display: block;
  margin: 64px 0;

  @media #{$tablet} {
    margin: 48px 0;
  }
  
  @media #{$mobile} {
    margin: 32px 0;
  }

  & > .languageSelector {
    position: absolute;
    top: -42px;
    right: 12px;
    
    @media #{$tablet} {
      top: -34px;
    }

    @media #{$mobile} {
      top: unset;
      right: 0;
      left: 0;
      bottom: 0;
      text-align: center;
    }

    & > span {
      cursor: pointer;

      &.selected {
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  & h1 {
    text-align: center;
    margin-bottom: 32px;

    @media #{$tablet} {
      margin-bottom: 24px;
    }    

    @media #{$mobile} {
      margin-bottom: 16px;
    }
  }

  & h2 {
    text-align: center;
    margin-bottom: 64px;

    @media #{$tablet} {
      margin-bottom: 48px;
    }    

    @media #{$mobile} {
      margin-bottom: 32px;
    }
  }

  & > .imageContainer {
    display: block;
    position: relative;
    width: 100vw;
    text-align: center;
    margin-bottom: 64px;

    @media #{$tablet} {
      margin-bottom: 48px;
    }    

    @media #{$mobile} {
      margin-bottom: 32px;
    }

    & > img {
      width: 85vw;
      max-width: 1200px;

      @media #{$tablet} {
        max-width: unset;
        width: 100vw;
      }    
  
      @media #{$mobile} {
        max-width: unset;
        width: 100vw;
      }
    }
  }

  & p {
    margin-bottom: 32px;

    @media #{$tablet} {
      margin-bottom: 24px;
    }

    @media #{$mobile} {
      margin-bottom: 16px;
    }

    & > span {
      &.signature {
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        max-width: inherit;
        font-family: 'Caveat', cursive;
      }
    }
  }

  & > .checkboxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    margin-bottom: 64px;

    @media #{$tablet} {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    @media #{$mobile} {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    & > h2 {
      font-weight: 500;
      margin-bottom: 32px;
      
      @media #{$tablet} {
        margin-bottom: 24px;
      }

      @media #{$mobile} {
        margin-bottom: 16px;
      }
    }

    & > .buttons {
      display: flex;
      margin-top: 32px;

      @media #{$mobile} {
        margin-top: 24px;
      }

      @media #{$mobile} {
        margin-top: 16px;
        flex-direction: column;
      }

      & > button {
        margin: 0 12px;;
        
        @media #{$mobile} {
          margin: 8px 0;
        }
      }
    }

    & > span {
      margin-top: 32px;

      @media #{$tablet} {
        margin-top: 16px;
      }

      @media #{$mobile} {
        margin-top: 8px;
      }
    }

    & > .container {
      display: flex;

      @media #{$tablet} {
        flex-direction: column;
      }

      @media #{$mobile} {
        flex-direction: column;
      }

      & > * {
        font-size: 24px;
        line-height: 48px;
        cursor: pointer;
        margin-right: 24px;

        @media #{$tablet} {
          margin-right: 0;
        }

        @media #{$mobile} {
          font-size: 18px;
          line-height: 30px;
          margin-right: 0;
        }

        & > input {
          width: 12px;
          height: 12px;
          margin-right: 12px;
          vertical-align: 33%;

          @media #{$mobile} {
            vertical-align: 0;
          }
        }
      }
    }
  }

  & > .moreInfo {
    padding-top: 28px;
    padding-bottom: 64px;

    @media #{$tablet} {
      padding-top: 21px;
      padding-bottom: 48px;
    }

    @media #{$mobile} {
      padding-top: 14px;
      padding-bottom: 32px;
    }

    & > p:last-child {
      margin-top: 32px;

      @media #{$tablet} {
        margin-top: 24px;
      }

      @media #{$mobile} {
        margin-top: 16px;
      }
    }
  }
}

.errorPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    margin-bottom: 24px;
    
    @media #{$mobile} {
      margin-bottom: 16px;
    }
  }

  & > p {
    text-align: center;
  }
}
