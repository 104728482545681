@import '../styles/variables';

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > .lds-heart {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    transform: rotate(45deg) scale(2);
    transform-origin: 32px 32px;

    & > div {
      top: 23px;
      left: 19px;
      position: absolute;
      width: 26px;
      height: 26px;
      background: $pink;
      animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

      &:after,
      &:before {
        content: " ";
        position: absolute;
        display: block;
        width: 26px;
        height: 26px;
        background: $pink;
      }

      &:before {
        left: -17px;
        border-radius: 50% 0 0 50%;
      }

      &:after {
        top: -17px;
        border-radius: 50% 50% 0 0;
      }
    }
  }
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
