
/* media queries */
$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1200px)";

/* Grid */
$resize-ratio: calc((100vw - 768px) / (1200 - 768));

/* Colors */
$black: #000;
$white: #fff;
$pink: #edb5bd;
$blue: #0f76c0;
$gray: #121738;
$red: #fc3c2d;
